<template>
    <!-- Coming soon page-->
    <div>
      <crearProfesionalSalud />
    </div>
  
  <!-- / Coming soon page-->
  </template>
  
  <script>
  /* eslint-disable global-require */
  
  import store from '@/store/index'
  import crearProfesionalSalud from '@/assets/componentes/lineaFrente/crearProfesional/crearProfesionalSalud.vue'
  
  export default {
    components: {
        crearProfesionalSalud,
    },
  }
  </script>