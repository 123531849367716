<template>
	<b-card-code
		title="Crear Profeisional"
	>
		<validation-observer ref="simpleRules">
			<b-form>
			<b-row>
				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="PRIMER NOMBRE"
							rules="required"
						>
						<b-form-input
							v-model="primer_nombre"
							:state="errors.length > 0 ? false:null"
							placeholder="Primer Nombre"
						/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>

				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="SEGUNDO NOMBRE"
						>
						<b-form-input
							v-model="segundo_nombre"
							:state="errors.length > 0 ? false:null"
							placeholder="Segundo Nombre"
						/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>

				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="PRIMER APELLIDO"
							rules="required"
						>
						<b-form-input
							v-model="primer_apellido"
							:state="errors.length > 0 ? false:null"
							placeholder="Primer Apellido"
						/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>

				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="SEGUNDO APELLIDO"
						>
						<b-form-input
							v-model="segundo_apellido"
							:state="errors.length > 0 ? false:null"
							placeholder="Segundo Apellido"
						/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>

				<!-- tipo de documento -->
				<b-col md="4">
					<b-form-select
						v-model="tipo_identificacion"
						:options="tipo_identificacion_lista"
						:state="tipo_identificacion === null ? false : true"
						
					/>
				</b-col>

				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="NÚMERO IDENTIFICACIÓN"
							rules="required"
						>
						<b-form-input
							v-model="numero_identificacion"
							:state="errors.length > 0 ? false:null"
							placeholder="Número Identificación"
							type="number"
						/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>

				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="TARJETA PROFESIONAL"
							rules="required"
						>
						<b-form-input
							v-model="tarjeta_profesional"
							:state="errors.length > 0 ? false:null"
							placeholder="Tarjeta Profesional"
						/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>


				<b-col md="4">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="CORREO ELECTRÓNICO"
							rules="required|email"
						>
							<b-form-input
								v-model="emailValue"
								:state="errors.length > 0 ? false:null"
								type="email"
								placeholder="Correo Electrónico"
							/>
								<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>

				<!-- especialidad -->
				<b-col md="4">
					<b-form-select
						v-model="especialidad"
						:options="tipo_especialidades"
						:state="especialidad === null ? false : true"
						
					/>
				</b-col>

				<!-- procedimiento -->
				<b-col md="4">
					<b-form-select
						v-model="procedimiento"
						:options="tipo_procedimiento"
						:state="procedimiento === null ? false : true"
					/>
				</b-col>

				<!-- sedes -->
				<b-col md="4">
					<b-form-select
						v-model="sede"
						:options="tipo_sedes"
						:state="sede === null ? false : true"
					/>
				</b-col>
        <div class="demo-inline-spacing">
           <!-- submit button -->
           <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Guardar
          </b-button>
          <b-button
            variant="secondary"
            type="submit"
            @click.prevent="cancelarCrear()"
          >
            Cancelar
          </b-button>
        </div>
			</b-row>
			</b-form>
		</validation-observer>
	</b-card-code>
  </template>
  
  <script>
	import BCardCode from '@core/components/b-card-code'
	import vSelect from 'vue-select'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
	BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormSelect 
  } from 'bootstrap-vue'
  import {
	required, email, confirmed, password,
  } from '@validations'
  
  export default {
	components: {
		BFormSelect ,
		vSelect,
		BCardCode,
	  ValidationProvider,
	  ValidationObserver,
	  BFormInput,
	  BFormGroup,
	  BForm,
	  BRow,
	  BCol,
	  BButton,
	},
	data() {
	  return {
		emailValue: '',
		primer_nombre: '',
		segundo_nombre: '',
		primer_apellido:'',
		segundo_apellido:'',
		tipo_identificacion: null,
		tipo_identificacion_lista:[
			{ 
				value: null,
				text: 'Tipo Documento'
			},
			{
				value:'CE',
				text:'Cédula de extranjería'
			},
			{
				value:'CC',
				text:'Cédula ciudadanía'
			},
			{
				value:'CD',
				text:'Carné diplomático'
			},
		],
		numero_identificacion:'',
		tarjeta_profesional:'',
		especialidad: null,
		tipo_especialidades:[
				{ 
					value: null,
					text: 'Especialidad'
				},
        {
          value:'MEDICINA GENERAL',
          text:'MEDICINA GENERAL'
        },
        {
          value:'NUTRICIÓN Y DIETÉTICA',
          text:'NUTRICIÓN Y DIETÉTICA'
        },
        {
          value:'ENFERMERÍA',
          text:'ENFERMERÍA'
        },
        {
          value:'PSICOLOGÍA',
          text:'PSICOLOGÍA'
        },
        {
          value:'TRABAJO SOCIAL',
          text:'TRABAJO SOCIAL'
        },
        {
          value:'FONOAUDIOLOGÍA',
          text:'FONOAUDIOLOGÍA'
        },
        {
          value:'FISIOTERAPIA',
          text:'FISIOTERAPIA'
        },
        {
          value:'TERAPIA RESPIRATORIA',
          text:'TERAPIA RESPIRATORIA'
        },
        {
          value:'TERAPIA OCUPACIONAL',
          text:'TERAPIA OCUPACIONAL'
        },
        {
          value:'CIRUGÍA GENERAL',
          text:'CIRUGÍA GENERAL'
        },
        {
          value:'DERMATOLOGÍA',
          text:'DERMATOLOGÍA'
        },
        {
          value:'GINECOLOGÍA Y OBSTETRICIA',
          text:'GINECOLOGÍA Y OBSTETRICIA'
        },
        {
          value:'INFECTOLOGÍA',
          text:'INFECTOLOGÍA'
        },
        {
          value:'MEDICINA FÍSICA Y REHABILITACIÓN',
          text:'MEDICINA FÍSICA Y REHABILITACIÓN'
        },
        {
          value:'MEDICINA INTERNA',
          text:'MEDICINA INTERNA'
        },
        {
          value:'ORTOPEDIA Y TRAUMATOLOGÍA',
          text:'ORTOPEDIA Y TRAUMATOLOGÍA'
        },
        {
          value:'OTORRINOLARINGOLOGÍA',
          text:'OTORRINOLARINGOLOGÍA'
        },
        {
          value:'PEDIATRÍA',
          text:'PEDIATRÍA'
        },
        {
          value:'NEUROLOGÍA',
          text:'NEUROLOGÍA'
        },
        {
          value:'NEUROLOGÍA PEDIÁTRICA',
          text:'NEUROLOGÍA PEDIÁTRICA'
        },
        {
          value:'PSIQUIATRÍA',
          text:'PSIQUIATRÍA'
        },
        {
          value:'UROLOGÍA',
          text:'UROLOGÍA'
        },
		{
          value:'RADIÓLOGO',
          text:'RADIÓLOGO'
        },
        {
          value:'QUÍMICO FARMACÉUTICO',
          text:'QUÍMICO FARMACÉUTICO'
        },
    ],
		procedimiento: null,
		tipo_procedimiento:[
			{ 
				value: null,
				text: 'Procedimiento'
			},
			{
				value:'LABORATORIO',
				text:'LABORATORIO'
			},
			{
				value:'IMAGENOLOGIA',
				text:'IMAGENOLOGIA'
			},
		],
		sede:null,
		tipo_sedes:[
			{ 
				value: null,
				text: 'Sede'
			},
			{
				value:'TAME PRINCIPAL',
				text:'TAME PRINCIPAL'
			},
			{
				value:'ARAUQUITA PRINCIPAL',
				text:'ARAUQUITA PRINCIPAL'
			},
			{
				value:'ARAUCA PRINCIPAL',
				text:'ARAUCA PRINCIPAL'
			},
			{
				value:'SARAVENA PRINCIPAL',
				text:'SARAVENA PRINCIPAL'
			},
			{
				value:'YOPAL PRINCIPAL',
				text:'YOPAL PRINCIPAL'
			},
		],
		
		

		
		required,
		password,
		email,
		confirmed,
	  }
	},
	methods: {

	  validationForm() {
		  this.$refs.simpleRules.validate().then(success => {
		  	if (success) {
          this.dataProfesional = {
            primer_nombre: this.primer_nombre,
            segundo_nombre: this.segundo_nombre,
            primer_apellido: this.primer_apellido,
            segundo_apellido: this.segundo_apellido,
            tipo_documento: this.tipo_identificacion,
            numero_documento: this.numero_identificacion,
            tarjeta_profesional: this.tarjeta_profesional,
            correo: this.emailValue,
            especialidad: this.especialidad,
            procedimiento: this.procedimiento,
            sede: this.sede
          }
          this.$store.dispatch('apiFast/crearProfesional', this.dataProfesional).then(response => response)

          this.$store.dispatch('apiFast/listarProfesionalSalud').then(response => {
            // console.log(response.data)
            this.$store.state.listaProfesional = response.data
          })

          this.$router.push({ name: 'linea-de-frente' })
              // eslint-disable-next-line
            }
        }
      )
	  },
    cancelarCrear() {
      this.$router.push({ name: 'linea-de-frente' })
    }
	},
  }
  </script>